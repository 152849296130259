body {
  background-color: white;
  font-family: 'Courier New', Courier, monospace;
}
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 13px;
  overflow: hidden;
  padding: 3px 3px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 13px;
  font-weight: normal;
  overflow: hidden;
  padding: 3px 3px;
  word-break: normal;
}
.tg .tg-row {
  text-align: left;
  vertical-align: top;
}
.tg .tg-header {
  background-color: #efefef;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
}

#key-up-container {
  display: none;
  z-index: 2;
  position: absolute;
  top: 50px;
  right: 50px;
  padding: 20px;
  background-color: green;
  color: white;
  text-align: center;
  font-size: 18px;
}
